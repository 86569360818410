.usage-metrics-modalV2 {
  width: 455px !important;
  height: 671px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .scrollable-modal {
    .scrolling.content {
      height: calc(100% - 48px);
      &:has(.banner-outer)
      {
        .scroll-window {
          height: calc(100% - 60px);
        }
      }
      .banner-outer.alert .icon-container {
        svg {
          height: 13px;
        }
      }
    }
    .retrieval-metrics{
      .usage-metrics-loader {
      position: absolute;
      top: 60px;
      width: 100%;
    }}
    
    .header {
      padding: 0 40px 8px 40px !important;
    }

    .retrieval-chart {
      padding: -16px -32px 0px -32px;
    }

    .retrieval-chart {
      transform: translateX(-4%);
    }

    .scroll-window {
      padding: 16px 32px 0px 32px;
      height: 100%;
      overflow: auto;
    }
  }
}

.ui.modals.dimmer {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  background: rgba($color: #b9b9b9, $alpha: 0.5);
}