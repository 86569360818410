.error-body {
  align-self: center;
  margin: 50px;
  width: 620px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 16px;
  @media (max-width:768px) {
    width: auto;
  }

  .error-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #707070;
  }

  .error-text {
    font-weight: 500;
    font-size: 20px;
    color: #707070;
  }

  .button {
    width: 100px;
  }
}
