.user-invite-action-modal {
  .modal-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
