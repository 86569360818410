.customer-page {
  .customer-list {
    .customer-list-header {
      margin: 24px;
      display: flex;
      flex-direction: row;
      gap: 24px;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .niagara-search {
        &>.input {
          min-width: 248px !important;
          @media (max-width: 380px) {
            min-width: 100% !important;
          }
        }
      }
    }

    >.cards-container {
      overflow-y: auto;
      padding: 0 16px;
      margin: 0;

      .card-wrapper {
        padding: 0 8px 16px 8px;

        .customer-card {
          height: 128px;

          .customer-card-container {
            margin: 24px;
            color: #303030;

            .customer-card-counts {
              margin-top: 12px;
            }
          }
        }
      }
    }

  }
}

@media (max-width: 800px) {
  .main-container>.sidebar-layout.no-icons.niagara-side-menu {
    &:not(.collapse) {
      .content-container {
        .customer-page>.page-content>.customer-list {
          .scuf-grid>.scuf-col>.customer-card {
            .customer-card-container {
              margin: 8px;
            }
          }
        }
      }
    }
  }
}