.edit-device.ui.modal.niagara-modal,
.edit-device-confirm.ui.modal.niagara-modal {
  width: 405px;

  .modal-heading {
    font-weight: 700;
  }

  .banner-outer.info {
    margin-bottom: 24px;
    .icon-container {
      svg {
        height: 16px;
      }
    }
  }
}

.edit-device.ui.modal.niagara-modal .device-info-container {
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  align-items: center;
  padding: 5px 10px;
  gap: 12px;
  margin-bottom: 35px;
}