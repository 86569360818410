[data-theme='dark'] {
  @import './DarkModeColor';

  body:has(.customer-page, .alarms-page, .project-device-page, .audit-log-list) {
    background-color: $background-color-1 !important;
    color: $font-color !important;

    i,
    .loader {
      color: $font-color !important;
    }

    // search box
    .prompt {
      color: $input-text-color !important;
    }

    input {
      background-color: $input-background-color !important;
      border: 1px solid $border-color-3 !important;

      &::placeholder {
        color: $input-text-color !important;
      }
    }

    //Niagara Button Alert Icon
    .niagara-button-2:not(.disabled).tertiary.alert {
      background: #202020;
      border: 1px solid#808080;
      svg > path {
        stroke: #f0f0f0;
      }
    }

    //Customer page
    a > .customer-card-container {
      color: $font-color !important;
    }

    .customer-list.niagara-card.raised {
      border-color: $border-color-1 !important;
      background-color: $background-color !important;

      .customer-card.niagara-card {
        border-color: $border-color !important;
        box-shadow: 1px 1px 1px 0px #00000026;
        background-color: $background-color !important;

        .customer-card-container {
          color: $font-color !important;
        }
      }
    }

    .niagara-label label {
      color: $font-color;
    }

    .niagara-input {
      background-color: $input-background-color;

      .ui.single-input .input-wrap {
        .input-box {
          color: $font-color;
        }
        button.with-active-effect:hover {
          background: $dropdown-hover-color;
          svg > path {
            stroke: $action-button-active-color;
          }
        }
      }
    }

    .ui.modal.niagara-modal {
      background-color: $input-background-color !important;
      .niagara-button-2.secondary-link {
        svg > path {
          stroke: $input-text-color;
        }
      }

      .scroll-window {
        background-color: $input-background-color;
      }

      .header {
        color: $font-color;
      }

      .content {
        color: $font-color;
        background-color: $input-background-color;
      }

      .banner-outer.info {
        border-color: $info-banner-border;

        .icon-container {
          background-color: $action-button-active-color;

          svg {
            > path {
              stroke: $font-color-2;
            }
          }
        }
      }

      .banner-outer.alert {
        border-color: $banner-alert-border;

        .icon-container {
          background-color: $banner-alert-color;
          svg {
            > path {
              stroke: $font-color-2;
            }
          }
        }
      }

    }

    .banner-outer {
      background-color: $background-color-1;

      .banner-main-header {
        color: $font-color !important;
        font-weight: 600px;
      }

      .banner-sub-header {
        color: $font-color !important;
        font-weight: 600px;
      }
    }

    /* Side bar css */
    .niagara-side-menu > .sidebar {
      color: $font-color !important;
      scrollbar-color: $font-color !important;
      background-color: $background-color !important;
      border-right: none;

      span,
      p,
      a {
        color: $input-text-color !important;
        &.active {
          span,
          a,
          p {
            color: $font-color !important;
          }
        }
      }
    }

    .page-content .sidebar-layout .sidebar-toggle .toggle-btn {
      svg > path {
        stroke: $stroke-color;
      }

      background-color: $input-background-color;
      border: 1px solid $border-color-6;
      box-shadow: 1px 1px 1px 0px #00000026;
    }

    .page-content .sidebar-layout .sidebar-toggle:before {
      background-color: $border-color-6;
    }

    // Page Header
    .menu-btn-item {
      svg > path {
        stroke: $font-color !important;
      }
    }

    .user-profile-button,
    .signout-button {
      color: $font-color !important;
      background-color: $background-color !important;
      border: #303030 !important;
      // border-radius: 0;

      .h-icon {
        color: $font-color !important;
      }
    }

    #header-user-profile-popup {
      .divider {
        display: none;
      }

      .signout-button {
        color: #f0f0f0 !important;
        border-color: #808080 !important;
        background-color: $background-color !important;

        path {
          stroke: $font-color !important;
        }
      }
    }

    //BreadCrumb
    .ui.breadcrumb.niagara-breadcrumb .divider,
    .ui.breadcrumb a.section,
    .ui.breadcrumb.niagara-breadcrumb a.section:last-of-type {
      color: $font-color;
    }

    //Niagara Tertiary Button
    .niagara-button-2:not(.disabled).tertiary {
      background: none;
      color: #c8c8c8;
      border: none;
      box-shadow: none;
    }
    .niagara-button-2:not(.disabled).secondary-link {
      color: $action-button-active-color;
      .no-content {
        svg > path {
          stroke: $input-text-color;
        }
      }
    }
  }
  /* Tooltip*/
  .alternate-tooltip.ui.tooltip.niagara-tooltip,
  .ui.tooltip.niagara-tooltip {
    background: $tooltip-background !important;
    color: $font-color-2 !important;
    &:before {
      background: $tooltip-background !important;
    }
  }

  /* Primary Button*/
  .niagara-button-2.primary {
    background: #b0b0b0 !important;
    color: #5c5c5c !important;
    &:not(.disabled) {
      background: $action-button-active-color;
      color: $font-color-2;
    }
  }

  /* Secondary Button*/
  .niagara-button-2:not(.disabled).secondary {
    background: $input-background-color !important;
    border: 1px solid $action-button-active-color;
    color: $action-button-active-color;
  }

  /* Radio Button*/
  .ui.radio.checkbox{
    &.checked {
      label:before {
        border-color: $action-button-active-color !important;
      }
    }
    label {
      color: $font-color !important;
      &:before {
        border-color: $stroke-color !important;
        background-color: $background-color-2 !important;
      }
      &:after {
        background-color: $action-button-active-color !important;
      }
    }
  }

  /* Snackbar*/
  .niagara-snackbar {
    background: $tooltip-background;
    color: $font-color-2;
  }
  
  /* Tab menu */
  .ui.scuf-tab.menu .item {
    color: $stroke-color-2 !important;
  
    &.active {
      color: $font-color !important;
    }
  }

  /* Primary-link */
  .niagara-button-2:not(.disabled).primary-link {
    color: $action-button-active-color;
    svg > path {
      stroke: $action-button-active-color;
    }
  }
  .niagara-button-2.primary-link {
    &.disabled {
      color: $stroke-color-2;
      svg > path {
        stroke: $stroke-color-2;
      }
    }
  }

  /* Alarms Filter */
  .niagara-multiselect {
    .multiselect-button {
      background: $background-color-1 !important;
      button:hover {
        background: $dropdown-hover-color;
        svg > path {
          stroke: $action-button-active-color;
        }
      }
      button.active {
        background: $dropdown-hover-color !important;
        svg > path {
          stroke: $action-button-active-color !important;
        }
      }
    }
    .multiselect-items {
      background: $background-color-1 !important;
      color: $input-text-color !important;
      .search-item {
        input {
          border: none !important;
        }
        svg > path {
          stroke: $input-text-color;
        }
      }
    }
  } 
  body:has(.filterby-fields-container) input {
    background-color: $background-color-1 !important;
    border: 1px solid $input-text-color !important;
  }
}

.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-right: 10px;
}