#register-device-v2 {
  width: 844px;
  margin: 0 auto;
  .header {
    align-items: start;
    justify-content: left;
    margin-top: 10px;
    .heading-content {
      font-size: 24px;
      line-height: 32px;
      font-weight: 800;
    }
  }
  .register-device-form {
    margin: 42px 24px 0px 24px !important;
    &.project-location-section {
      margin-top: 16px !important;
    }
    .form-container {
      height: 64px !important ;
      display: flex;
      gap: 100px;
      width: 100%;
      .full-width {
        flex: 1 1 50%;
      }
      .disabled {
        .niagara-label {
          label {
            color: #868686;
          }
        }
      }
    }
    .niagara-input .ui.single-input .input-wrap .input-box {
      height: 32px !important;
    }
    .below-text {
      min-height: 0px;
      margin: 0px;
    }
  }
  .license-title {
    font-weight: 700;
    margin-top: 1rem;
    margin: 32px 24px auto 24px;
  }
  .no-license {
    margin: 0px 24px auto 24px !important;
    background-color: #f4f4f4;
    padding: 70px 10px 70px 15px;
    .license-link {
      color: #005eac;
      font-weight: 700;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 42px;
    margin: auto 42px 16px;
    .button-container {
      display: flex;
      gap: 24px;
    }
  }
}
