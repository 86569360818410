.service-notification-banner {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0px;
    background: #404040;
    color: #f0f0f0;
    font-weight: 500;
    padding: 16px 40px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    z-index: 999;
    
    .info-icon
    {
      min-width: 64px;
      height: 64px;
      background-color: #64C3FF;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .close-button {
      min-width: 16px !important;
      height: 16px;
      margin-left: auto;
    }
  }