/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
.tooltip-container {
  display: flex;
  width: 140px;
  margin-bottom: 12px;

  &:nth-child(1) {
    margin-top: 8px;
  }

  .legend-container {
    display: flex;
    gap: 8px;
    flex: 1 1;

    .legend-tooltip {
      width: 16px;
      height: 16px;
    }
  }
}

[data-theme="dark"] {
  @import '../../../DarkModeColor';

  .highcharts-tooltip-box {
    fill: black;
  }

  .tooltip-container {
    color: $font-color !important;
  }
}
