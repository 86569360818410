/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

.page-content .sidebar-layout {
   gap: 8px;

   .sidebar {
      border-radius: 8px;

      .collapsible-panel {
         min-width: 324px;
         box-shadow: 2px 0px 0px #e0e0e0;
         height: 100%;
         overflow: hidden;
         &:has(.project-list-footer) {
            .left-data-container {
               height: calc(100% - 135px) !important;
            }
         }
         .left-data-container {
            height: calc(100% - 75px) !important;
         }
         .project-list {
            height: 100%;
            &.niagara-card{
               overflow: hidden;
            }
            .project-list-header {
               padding: 24px;
            }

            .project-list-footer {
               margin: 12px;
               display: flex;
               flex-direction: column;
               align-items: center;
            }

            .project-list-body {
               padding: 24px 0;
               display: flex;
               flex-direction: column;
               align-items: center;
               gap: 20px;
               height: 100%;

               .search-wrap {
                  width: calc(100% - 48px);

                  .input {
                     width: 100%;
                  }
               }

               .sidebar-project {
                  padding-left: 24px;
                  padding-right: 24px;
                  flex: 1;

                  & > .sidebar {
                     border: 0;
                     width: 100%;
                  }
               }

               .sidebar-project > .sidebar .ui.menu {
                  display: flex;
                  flex-direction: column;
                  gap: 0;

                  .item {
                     text-transform: none;
                     padding: 0;
                     padding-right: 10px;
                     border: none !important;

                     &.active {
                        .all-projects-selection,
                        .project-selection-name {
                           border-left: 2px solid #1792e5;
                        }
                     }

                     .all-projects-selection,
                     .project-selection-name {
                        border-left: 2px solid transparent;
                        padding-left: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 0px;
                     }

                     .all-projects-selection {
                        margin-bottom: 8px;
                        width: auto;
                        display: flex;

                        div {
                           flex: 1 1;
                        }
                     }

                     .project-selection-name {
                        flex: 1;
                        margin-top: 6px;
                        margin-bottom: 6px;
                        line-height: 28px;
                        display: flex;

                        ~ .niagara-button-2 {
                           margin: auto 0;
                           min-width: 28px;
                           height: 28px;
                        }

                        span {
                           flex: 1 1;
                        }
                     }

                     &:not(:hover) .project-selection-name ~ .niagara-button-2 {
                        display: none;
                     }

                     &:hover,
                     &.active {
                        background: white;
                     }
                  }
               }
            }

            .project-list-footer {
               .button {
                  width: calc(100% - 30px);
               }
            }
         }
      }
   }

   &:not(.collapse) {
      > .sidebar {
         width: 324px;

         .project-list {
            overflow-x: auto;
         }
      }

      .sidebar-toggle {
         margin-left: 0;
      }
   }

   .sidebar-toggle {
      margin-left: -8px;
      position: relative;

      &:before {
         content: '';
         position: absolute;
         left: calc(50% - 1px);
         top: 32px;
         width: 2px;
         height: calc(100% - 32px);
         background: #e0e0e0;
      }

      .toggle-btn {
         height: 32px;
         width: 20px;
         border-color: #707070 !important;
         padding: 8px 2px 8px 2px;
         border-radius: 4px;
         box-shadow: 1px 1px 1px 0px #00000026;
      }

      .niagara-button-2.no-content {
         min-width: 20px;
      }
   }

   .main-view {
      flex: auto;
      min-width: 0;
      height: 100%;
      .niagara-card {
         height: 100%;
      }
   }
}
.sidebar-layout.no-icons.niagara-side-menu {
   &:not(.collapse) {
      .sidebar-layout {
         .sidebar-toggle .toggle-btn {
            pointer-events: none;
            cursor: not-allowed;
         }

         .main-view {
            flex: auto;
         }
      }
   }
}
[data-theme='dark'] {
   @import '../../_DarkModeColor';
   .collapsible-panel {
      .project-list {
         .project-list-body {
            .item {
               &:hover,
               &.active {
                  background: none !important;
               }

               .sidebar-label {
                  color: #f0f0f0 !important;

                  .project-selection-name {
                     color: #d0d0d0;
                  }
               }
            }

            .project-name-field {
               input,
               input:active,
               input:focus {
                  background-color: #404040 !important;
                  border: 1px solid #808080 !important;
                  color: #d0d0d0 !important;
                  border-radius: 4px;
                  width: 100%;
                  outline: none;
                  margin: 2px 0px;
                  height: 32px;
               }
            }
         }
      }
   }
}
