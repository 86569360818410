.message-box {
  width: 370px;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  .message {
    font-size: 16px;
    line-height: 24px;
  }
}
