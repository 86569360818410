.license-table.niagara-data-table {
  .item-count-row {
    display: none;
  }
  .p-column-title {
    text-transform: none !important;
    font-size: 16px !important;
  }
  .p-datatable-row:has(#highlighted-license) {
    background: #f7f7f7 !important;
    td {
      font-weight: 700;
    }
  }
  .action-link {
    float: right;
  }
  .p-datatable-scrollable-body {
    min-height: 72px !important;
  }
}
