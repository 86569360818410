[data-theme='dark'] {
  @import './DarkModeColor';
  body:has(.project-device-page) {
    .niagara-select {
      background-color: $input-background-color !important;
      color: $font-color !important;

      .ui.active.visible.selection.dropdown {
        background-color: $input-background-color;
      }

      .ui.scuf-dropdown-wrapper > .ui.selection.dropdown {
        background-color: $input-background-color;
        border: 1px solid $border-color-4;
        .text {
          color: $font-color !important;
        }
        i.icon.chevron.down {
          background: none;
        }
        i.icon.chevron.down:before {
          color: $font-color !important;
        }
        .visible.menu {
          background-color: $background-color;

          .selected.item {
            background-color: $dropdown-hover-color;
            color: $action-button-active-color !important;
          }

          .item {
            color: $font-color !important;
          }
          .item:hover {
            background-color: $dropdown-hover-color;
            color: $action-button-active-color !important;
          }
        }
      }

      .ui.active.visible.selection.dropdown {
        border: 2px solid $action-button-active-color !important;
        border-bottom: none !important;
        .visible.menu {
          outline: none;
          border-top: 2px solid $action-button-active-color !important;
        }
      }
    }
  }
}
