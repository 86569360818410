.nds-subscription {
    display: flex;

    span {
        font-size: 14px;
        &:nth-child(2){
            margin-left: auto;
            padding-bottom: 16px;
        }
    }
}