.device-more-actions-button {
  .popup-pointer {
    display: inline-block;
  }
}

.device-more-actions-popup.ui.popup.menu {
  margin: 0 !important;
  width: 144px;
  box-shadow: 0px 4px 7px 0px #00000033;
  border-radius: 4px;
  z-index: 1;
  min-height: auto;

  &>button {
    height: 40px;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 0px;

    &:nth-last-child(1) {
      border-top: 2px solid #d0d0d0;
    }

    &:hover {
      color: #005eac;
      background: #def9ff !important;
    }
  }
}

[data-theme='dark'] {
  .device-more-actions-popup.ui.popup.menu {
    background: #404040;

    &>button {
      color: #F0F0F0;

      &:nth-last-child(1) {
        border-top: 2px solid #707070 !important;
      }

      &:hover {
        background: #353535 !important;
        color: #64C3FF !important;
      }
    }
  }
}