.ui.niagara-menu {
  background-color: transparent;
  border-bottom: unset !important;

  .page-header-menu.ui.menu {
    align-items: center;

    .item {
      min-width: 0;
    }

    .menu-btn-item {
      padding: 0;
    }

    .logo-item {
      padding: 16px 12px !important;
    }

    .header-icons-container {
      display: flex;
      gap: 8px;

      .user-profile-button {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        cursor: pointer;
      }
      .notification-bell {
        position: relative;
        .notification-dot {
          position: absolute;
          height: 8px;
          width: 8px;
          cursor: pointer;
          background-color: #EE3124;
          border-radius: 50%;
          top: 4px;
          right: 4px;
        }
    }
    }
  }
}

#header-user-profile-popup {
  margin: 0;
  margin-top: 2px;
  min-height: 0;
  background: #f0f0f0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  width: unset;
  min-width: 144px;
  border-radius: 4px;

  .ui.divider {
    border: 1px solid #e0e0e0 !important;
  }

  .signout-button {
    width: 100%;
  }
}

body:not(:has(.customer-page)) {
  .toggle-theme-wrapper {
    display: none;
  }
}