[data-theme='dark'] {
  @import './DarkModeColor';
  body:has(.alarms-page, .project-device-page, .audit-log-list) {
    .ui.raised.card.niagara-card {
      background-color: $background-color-2;
      box-shadow: 1px 1px 1px 0px #00000026 !important;

      .niagara-data-table .item-count-row {
        border-top: 2px solid $border-color-6 !important;
        .action-bar .chip-container .chip {
          .niagara-button-2.secondary-link {
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .niagara-data-table
        .ui.forge-datatable-wrap
        .p-datatable
        .p-datatable-thead
        th
        .p-column-title,
      .niagara-pagination .ui.pagination .page-btn.item-btn.active,
      .ui.breadcrumb.niagara-breadcrumb a.section:last-of-type,
      .niagara-data-table
        .ui.forge-datatable-wrap
        .p-datatable
        tbody.p-datatable-tbody
        > tr
        > td {
        color: $input-text-color;
      }
      .niagara-pagination {
        .go-to-page {
          .ui.single-input .input-wrap {
            background: none !important;
          }
        }

        .ui.pagination {
          .page-btn.item-btn.active {
            border-bottom: $action-button-active-color 2px solid !important;
          }
        }
      }
      .niagara-data-table .ui.forge-datatable-wrap .p-datatable {
        .p-datatable-thead th {
          border: 2px solid $border-color-5;
          border-left: none !important;
          .p-column-title
            .sortable-header
            .niagara-button-2.secondary-link.active {
            background: #467795;
          }
        }

        tbody.p-datatable-tbody > tr {
          background-color: $background-color-2 !important;
          color: $input-text-color;
          border-bottom: 1px solid $border-color-5 !important;

          > td .p-checkbox .p-checkbox-box {
            background-color: $background-color-2;
            border: 1px solid $border-color-6;
          }
        }
        tbody.p-datatable-tbody > tr:hover,
        tbody.p-datatable-tbody > tr.p-highlight {
          background-color: #505050 !important;
        }
      }

      .p-column-title .niagara-button-2:not(.disabled).secondary-link {
        svg > path {
          stroke: $input-text-color;
        }
      }

      .niagara-button-2.with-active-effect:not(.disabled):hover {
        background-color: #467795;
      }

      .ui.forge-datatable-wrap .p-datatable .p-datatable-thead tr,
      .niagara-pagination,
      .ui.pagination,
      .ui.pagination .page-btn,
      .niagara-button-2.with-active-effect:not(.disabled).active:not(:hover) {
        background-color: $background-color-2;
      }

      .niagara-pagination .ui.pagination .page-btn,
      .niagara-pagination .go-to-page,
      .niagara-pagination .item-count-display,
      .niagara-pagination .ui.pagination .page-btn.nav-btn i:before,
      .niagara-data-table
        .ui.forge-datatable-wrap
        .p-datatable
        tbody.p-datatable-tbody
        > tr
        > td
        .cell-data-wrap {
        color: $input-text-color;
      }

      .niagara-pagination .go-to-page .ui.single-input .input-box {
        background-color: $background-color !important;
        border: 1px solid $border-color-4 !important;
        color: $input-text-color;
      }
      .niagara-pagination .go-to-page .ui.single-input .input-box:invalid {
        border: 1px solid $invalid-text-color !important;
        color: $invalid-text-color;
      }
      .niagara-data-table
        .ui.forge-datatable-wrap
        .ui.fitted.checkbox.select-all-checkbox
        label:before {
        background-color: $background-color-2;
        border: 1px solid $stroke-color;
        border: 1px solid $border-color-4;
      }

      .niagara-button-2.with-active-effect:not(.disabled).active svg *[stroke] {
        stroke: $action-button-active-color;
      }

      .ui.forge-datatable-wrap
        .p-datatable.p-datatable-scrollable
        .p-datatable-scrollable-header-box {
        th:last-child {
          border-right: none !important;
        }

        background-color: $background-color-2;
      }
    }
  }
}
