$font-color: #f0f0f0;
$font-color-2: #303030;
$border-color: #c0c0c0;
$border-color-1: #303030;
$border-color-2: #585858;
$border-color-3: #808080;
$border-color-4: #909090;
$border-color-5: #e0e0e0;
$border-color-6: #707070;
$background-color: #272727;
$background-color-1: #202020;
$background-color-2: #404040;
$input-text-color: #d0d0d0;
$input-background-color: #404040;
$stroke-color: #b0b0b0;
$stroke-color-2: #d0d0d0;
$action-button-active-color: #64c3ff;
$banner-alert-color: #ff9847;
$banner-alert-border: #d1670b;
$selected-item-background-color: #606060;
$invalid-text-color: #d60b13;
$info-banner-border: #0072c2;
$tooltip-background: #f4f4f4;
$dropdown-hover-color: #467795;
