[data-theme='dark'] {
  @import './DarkModeColor';
  .project-device-lists,
  .alarms-page {
    .sidebar-layout > .sidebar {
      border-right: none;
      background: $background-color-2;
      border-color: $border-color-1;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
      color: $font-color;
      box-shadow: none;

      .ui.divider {
        border: 1px solid $border-color-6 !important;
      }

      .collapsible-panel {
        .project-list {
          .project-list-body {
            .item {
              &:hover,
              &.active {
                background: none !important;
              }

              .sidebar-label {
                color: $font-color !important;
                .project-selection-name {
                  color: $input-text-color;
                }
              }
            }

            .project-name-field {
              input,
              input:active,
              input:focus {
                background-color: $background-color-2 !important;
                border: 1px solid $border-color-3 !important;
                color: $input-text-color !important;
                border-radius: 4px;
                width: 100%;
                outline: none;
                margin: 2px 0px;
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
}
